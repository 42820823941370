import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/style.css"

import Video from "../website_video.mp4";

const IndexPage = () => (
    <Layout>
        <SEO title="Home" keywords={[`gatsby`, `application`, `react`]}/>

        <div className={"video-bg"}>
            <video autoPlay muted loop id="my-video">
                <source src={Video} type="video/mp4"/>
            </video>
        </div>

        <div class="live-video">
            <iframe title="live-s-moreira-and-band" width="853" height="480" src="https://www.youtube.com/embed/Pkfc8mh377E" frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen></iframe>
        </div>

        <div className="contact-bar">
            <a href="https://www.facebook.com/themuddshow/" target="_blank" rel="noopener noreferrer">Facebook</a>
            <a href="https://www.instagram.com/themuddshow/" target="_blank" rel="noopener noreferrer">Instagram</a>
            <a href="mailto:edoardo@themuddshow.tv">Contact</a>
        </div>

    </Layout>
);

export default IndexPage
